@import url('https://fonts.googleapis.com/css?family=Oxygen:300,400,700');
@import "~bootstrap/scss/bootstrap";
@import "~jqwidgets-framework/jqwidgets/styles/jqx.base.css";
@import "~jquery-confirm/dist/jquery-confirm.min.css";

body, html {
    height: 100%;
    overflow: auto;
}

body {
    background-image: url("/build/assets/images/main_background_image.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: 'Oxygen', sans-serif;
}

.form-control {
    border:none;
    border-bottom: solid 1px #c3d0e5;
    border-radius: 0px;
}

.btn, a {
    color: #808ea3;
}

.text-muted {
    color: #808ea3!important;
}
